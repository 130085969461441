import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/LayoutENG'
import BlogRoll from '../components/BlogRoll'

import Certyfikat from '../../static/img/certyfikatTUV.webp'


import "../components/layout.css"

export const JakoscPageTemplateENG = ({  intro }) => (
         <div>
           <div>
             <div></div>
    </div>
    <section className="section section--gradient antyPadding">
             <div className="container">
        <div className="section AboutP antyPadding">
                 <div className="columns">
                   <div className="column is-10 is-offset-1 StoPro">
                     <div className="content">
                       <div className="columns">
                  <div className="column is-12">
                  </div>
                       </div>
                <div className=""><h3 className="has-text-weight-semibold is-size-2 headingCSS5">
                  {intro.heading}<hr className="hr"></hr>
                </h3>
                  <div className="grid-containerJakosc">
                    <div className="JakoscOpis">Ekochron understands quality as not just a label but a value confirmed by all customers. The following proves the high quality of Ekochron products:</div>
                    <div className="jo1 joUniversall"><p className="Naglowek">1. own laboratory</p><p className="opisJakosc">The company has its own laboratory, where strength and resistance to external factors are tested.</p></div>
                    <div className="jo5 joUniversall"><p className="Naglowek">5. skilled and experienced people</p><p className="opisJakosc">Our products quality is guarded by highly skilled employees, whose knowledge and experience are the foundation for the company development and success.</p></div>
                    <div className="jo2 joUniversall"><p className="Naglowek">2. compliance with norms</p><p className="opisJakosc">All goods are also tested in external laboratories for the compliance with En-PN 516 (roof communication) and EN-PN 517 (safety hooks) norms.</p></div>
                    <div className="jo6 joUniversall"><p className="Naglowek">6. cooperation with ITB and TZUS</p><p className="opisJakosc">Ekochron cooperates with the Building Research Institute (ITB) and since 2004 - with the Czech Building Engineering Institute Ostrava Branch (Technický a zkušební ústav stavební Praha TZUS) in Prague.
And it was in 2004 that we implemented, as a first company in Poland, safety roof hooks that protect against falls from height.
Since that time, all our EU-norms harmonized products have been implemented in liaison with TZUS, because this institute guarantees the correct construction and the high quality of our products.</p></div>
                    <div className="jo3 joUniversall"><p className="Naglowek">3. ISO-9001 implementation</p><p className="opisJakosc">In 2000 we implemented the ISO-9001 Quality Management System controlled by RW TUV. Acting in line with ISO stabilised the company position in the home market and allowed us to consequently and continuously improve products quality, which in turn opened the door to the European market.</p></div>
                    <div className="jo7 joUniversall"><p className="Naglowek">7. reliability in Poland and abroad</p><p className="opisJakosc">The high quality of the products we offer made Ekochron products available in many European countries.</p></div>
                    <div className="jo4 joUniversall"><p className="Naglowek">4. own powder paint-shop</p><p className="opisJakosc">Investments in the infrastructure and machines left us with our own paint-shop and a more automated processes, which translated into a better potential and a shorter reaction time to the Customer needs.</p></div>
                    <div className="jo8 joUniversall"><p className="Naglowek">8. new directions in development</p><p className="opisJakosc">A close cooperation with customers and following the market needs make us able to develop new products being at the cutting edge of the roof hardware industry, making us its leader and a guiding light for the competition.</p></div>
                    <div className="JakoscOpis2">We are happy with the rising number of companies establishing cooperation with our company. Presently we supply almost 500 customers in Poland. We also deliver our products abroad.

Many thanks to all who have been with us for years and welcome all those who still hesitate.</div>
                  </div>



                       </div>
                <div className="imgALL divCertyfikat"><img alt=" " className="imgCertyfikatJakosci"  src={Certyfikat}></img></div>
                       <div className="columns artykulyProd">
                         <div className="column is-12 has-text-centered">

                         </div>
                       </div>
                       <div className="column is-12 wpisy">
                         <h3 className="has-text-weight-semibold is-size-2">
                           The last notes:
                         </h3>
                         <BlogRoll />
                         <div className="column is-12 has-text-centered">
                           <Link className="btn" to="/blog">
                             Read more
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
         </div>
       );

JakoscPageTemplateENG.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  gallery: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const JakoscPageENG = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <JakoscPageTemplateENG
        image={frontmatter.image}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        gallery={frontmatter.gallery}
      />
    </Layout>
  )
}

JakoscPageENG.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default JakoscPageENG

export const pageQuery = graphql`
         query JakoscPageTemplateENG {
           markdownRemark(
             frontmatter: { templateKey: { eq: "jakosc-pageENG" } }
           ) {
             frontmatter {
               heading
               description
               subheading
               intro {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                   text
                 }

                 heading
                 description
               }
               gallery {
                 blurbs {
                   image {
                     childImageSharp {
                       fluid(maxWidth: 780, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }

                 }

               }
             }
           }
         }
       `;
